import React from 'react'
import PropTypes from 'prop-types'
import Seo from './seo.js'
// import Helmet from 'react-helmet'
// import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  // return (
  //   <StaticQuery
  //     query={graphql`
  //       query SiteTitleQuery {
  //         site {
  //           siteMetadata {
  //             title
  //           }
  //         }
  //       }
  //     `}
  //     render={data => (
  //       <>
  //         <Helmet
  //           title={data.site.siteMetadata.title}
  //           meta={[
  //             { name: 'description', content: 'felix.wu v2021' },
  //             { name: 'keywords', content: 'felix.wu, felixwu, runwuf' },
  //           ]}
  //         >
  //           <html lang="en" />
  //         </Helmet>
  //         {content}
  //       </>
  //     )}
  //   />
  // )

  return (
        <>
        <Seo />
          {content}
        </>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
